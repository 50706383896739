<template>
  <div>
    <div v-if="true" class="row-message" :class="el.type == 1 ? 'left' : 'right'">
      <div class="row-message-inner">
        <div v-if="messageUser" class="pink--text">{{ messageUser }}</div>
        <div v-if="true">{{ el.message }}</div>
        <div v-else-if="el.type == 'image'">
          <a :href="el.media.url" target="blank"><img :src="el.media.url" style="max-width: 300px" /></a>
        </div>
        <div v-else-if="el.type == 'sticker'">
          <img :src="el.media.url" style="max-width: 300px" />
        </div>
        <div v-else-if="el.type == 'file' && el.media != null">
          <a :href="el.media.url" target="blank"><v-icon x-large>fas fa-file</v-icon></a>
          <div>{{ el.media.filename }}</div>
        </div>
        <div v-else-if="el.type == 'audio' && el.media != null">
          <audio controls>
            <source :src="el.media.url" :type="el.media.contentType" />
          </audio>
        </div>
        <div class="row-message-inner-date">
          {{ getTimeString(el.createdon) }}
          <span v-if="el.direction == 2">
            <i v-if="el.status == 1 || el.status == 2" class="fas fa-check"></i>
            <i v-else-if="el.status == 3" class="fas fa-check-double"></i>
            <i v-else-if="el.status == 4" class="green--text fas fa-check-double"></i>
          </span>
        </div>
      </div>
    </div>
    <v-flex v-else d-flex mx-1 py-1 sm-pb-0 xs12 :id="`message-${item.id}`" :justify-end="item.type == 2" :justify-start="item.type == 1">
      <v-card
        @click.left="$emit('click', $event)"
        :color="item.type == 2 ? 'primary lighten-4' : 'secondary lighten-4'"
        :class="{
          'ml-15': item.type == 2 && !$vuetify.breakpoint.xsAndDown,
          'mr-15': item.type == 1 && !$vuetify.breakpoint.xsAndDown,
          'ml-2': item.type == 2 && $vuetify.breakpoint.xsAndDown,
          'mr-2': item.type == 1 && $vuetify.breakpoint.xsAndDown,
        }"
      >
        <v-list-item three-line>
          <v-list-item-content>
            <div class="mb-2" :class="{ 'font-weight-bold': item.viewed == 0 && item.type == 1 }">{{ item.message }}<br /><br /></div>

            <v-list-item-subtitle>
              <div class="d-flex">{{ messageDate }}<v-spacer /> {{ messageUser }}</div>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-flex>
  </div>
</template>

<script>
import { components } from "@/components/mixings";

export default {
  mixins: [components],
  components: {},
  props: {
    id: Number,
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},

  computed: {
    el() {
      return this.item;
    },
    messageUser() {
      let res = !this.item.createdby_id ? this.item.lead_name : this.item.user_name;
      return res;
    },
    messageDate() {
      const d = new Date(this.item.createdon);
      const n = new Date();
      let res = d.date == n.date ? d.shorttime : d.datetime;
      return res;
    },
  },
  watch: {},
  methods: {
    getDateString(d) {
      let a = new Date(d);
      return a.toLocaleDateString("ru");
    },
    getTimeString(d) {
      let a = new Date(d);
      return a.toLocaleTimeString("ru", { hour: "2-digit", minute: "2-digit" });
    },
  },
};
</script>
<style lang="scss">
@mixin table-style($color, $borderColor, $backgroundColor, $backgroundColorSelected, $backgroundColorSecond, $backgroundColorHover) {
  .comments-body {
    .comment-row {
      border-bottom: 1px solid $borderColor;
      background-color: $backgroundColor;
      &:hover {
        background: $backgroundColorHover;
      }
      &.selected {
        background-color: $backgroundColorSelected;
      }
      &.notviewed {
        font-weight: bold;
      }
    }
    .comment-row:nth-child(odd) {
      background-color: $backgroundColorSecond;
      &:hover {
        background-color: $backgroundColorHover;
      }
      &.selected {
        background-color: $backgroundColorSelected;
      }
      &.notviewed {
        font-weight: bold;
      }
    }
  }
}
.theme--dark {
  $color: #bdbdbd;
  $borderColor: #808080;
  $backgroundColor: #161616;
  $backgroundColorSelected: hsl(60, 75%, 81%);
  $backgroundColorSecond: lighten($backgroundColor, 3%);
  $backgroundColorHover: lighten($backgroundColor, 10%);
  @include table-style($color, $borderColor, $backgroundColor, $backgroundColorSelected, $backgroundColorSecond, $backgroundColorHover);
}
.theme--light {
  $color: #1a1a1a;
  $borderColor: #a3a3a3;
  $backgroundColor: #ffffff;
  $backgroundColorSelected: hsl(60, 75%, 81%);
  $backgroundColorSecond: darken($backgroundColor, 5%);
  $backgroundColorHover: darken($backgroundColor, 10%);
  @include table-style($color, $borderColor, $backgroundColor, $backgroundColorSelected, $backgroundColorSecond, $backgroundColorHover);
}
</style>
